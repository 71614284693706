import React from 'react'
import styled from 'styled-components'

import Archive from '../components/Archive/archive'
import Layout from '../components/Layout/layout'
import Listing from '../components/Listing/listing'
import SEO from '../components/SEO/seo'

const size = {
  laptop: '1024px',
}

const device = {
  laptop: `(min-width: ${size.laptop})`,
}

const BlogLayout = styled.main`
  max-width: 90%;
  padding-top: 1rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 8px;
  margin-bottom: 10px;

  @media ${device.laptop} { 
    grid-template-columns: 3fr 1fr;
    grid-gap: 40px;
  }
`

const Blog = () => (
  <Layout>
    <SEO title="Blog" />
    <BlogLayout>
      <Listing />
      <Archive />
    </BlogLayout>
  </Layout>
)

export default Blog