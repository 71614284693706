import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import SEO from '../SEO/seo'

const Post = styled.article`
  box-shadow: 0px 3px 10px rgba(25, 17, 34, 0.05);
  padding: 1rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  background: #e9ebf2;
  a {
    color: #000;
    text-decoration: none;
  }
  .read-more {
    ${props => props.theme.defaults.secondaryText};
    color: ${({ theme }) => theme.colors.primary.dark};
    text-decoration: underline;
  }
`

const StyledTitle = styled.h2`
  ${props => props.theme.defaults.primaryTextUpper};
  font-size: 40px
  line-height: 96%;
  margin-bottom: 0;
`
const StyledDate = styled.p`
  ${props => props.theme.defaults.secondaryText};
`
const StyledExcerpt = styled.p`
  ${props => props.theme.defaults.secondaryText};
  margin-bottom: 10px;
`

const LISTING_QUERY = graphql`
  query BlogPostListing {
    allMarkdownRemark(limit: 5, sort:{
      order: DESC
      fields: [frontmatter___date]
    }) {
      edges{
        node {
          excerpt
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            slug
          }
        }
      }
    }
  }
`
const Listing = () => (
  <div>
    <SEO title="Blog" keywords={[`dog`, `mushing`, `sledding`, `RokkinCat`, `game`, `race`, `development`]} />

    <StaticQuery 
      query={LISTING_QUERY}
      render={({allMarkdownRemark}) => (
        allMarkdownRemark.edges.map(edge => (
          <Post key={edge.node.frontmatter.slug}>  

            <Link to={`/posts${edge.node.frontmatter.slug}`}>
              <StyledTitle>{edge.node.frontmatter.title}</StyledTitle>
            </Link>
            <StyledDate>{edge.node.frontmatter.date}</StyledDate>
            <StyledExcerpt>{edge.node.excerpt}</StyledExcerpt>
            <Link class='read-more' to={`/posts${edge.node.frontmatter.slug}`}>
              Read More
            </Link>

          </Post>
        ))
      )}
    />
  </div>
)

export default Listing