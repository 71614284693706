import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

const size = {
  laptop: '1024px',
}

const device = {
  laptop: `(min-width: ${size.laptop})`,
}

const StyledAside = styled.aside`
  background: #1d1f29;
  height: 100%;
  padding-top: 10px;
  text-align: center;
  color: white;
  border-radius: 4px;
  h3 {
    ${props => props.theme.defaults.primaryTextUpper};
  }

  @media ${device.laptop} { 
    height: 50%;
  }
`
const ArchiveList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  a {
    text-decoration: underline;
  }
`

const POST_ARCHIVE_QUERY = graphql`
query BlogPostArchive {
  allMarkdownRemark(limit: 5, sort:{
    order: DESC
    fields: [frontmatter___date]
  }) {
    edges{
      node {
        frontmatter {
          title
          slug
        }
      }
    }
  }
}
`

const Archive = () => (
  <StaticQuery
    query={POST_ARCHIVE_QUERY}
    render={({allMarkdownRemark}) => (
      <>
      <StyledAside>
        <h3>Archive</h3>
        <ArchiveList>
        { allMarkdownRemark.edges.map(edge => (
          <li key={edge.node.frontmatter.slug}>
            <Link to={`/posts${edge.node.frontmatter.slug}`}>{edge.node.frontmatter.title}</Link>
          </li>
        ))}
        </ArchiveList>
      </StyledAside>
      </>
    )}
  />
)

export default Archive